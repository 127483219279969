.inquiryLayout {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    right: -100%;
    top:0;
    position: fixed;
    background-color: black;
}
.closeInquiryBtn{
    transition: all 1s ease-in-out;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%) rotate(-90deg);
    background: none;
    border: none;
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 300;
    cursor: pointer;
}


.formContainer{
    width: 30vw;
    display: flex;
    flex-direction:column ;
    align-items: flex-start;
    /*position: absolute;*/
    /*top:50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    color: white

}
.formContainer h1{
    width: 100%;
    font-weight: 500;
}
.formContainer h2{
    font-weight: 400;
}
.formContainer p{
    margin: 1rem 0;
    font-size: 18px;
}
.formContainer button{
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    transition: 0.5s;
    font-weight: 500;
    cursor: pointer;
    background: transparent;
    border: none;
    color: white;
    border: 2px solid white;
}

@media screen and (max-width: 1100px) {
    .closeInquiryBtn{
        left: 5%;
        top: 93%;
        transform:none;
    }
    .formContainer{
        width: 50vw
    }
}

@media screen and (max-width: 610px) {
    .closeInquiryBtn{
        left: 50%;
        transform: translateX(-50%) ;
    }
    .formContainer{
        width: 100vw;
    }
}