footer{
    margin-top: 5rem;
    min-height: 60vh;
    color: white;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 3px solid white;
    overflow: hidden
}
footer > div{
    transition: .5s all ease-in-out;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
footer > div h3{
    font-size: 0.83em;
    font-weight: 300;
    display: inline-block;
    position: relative;
    max-width: max-content;
}

footer > div h3::after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;

}
footer > div:not(:first-child):hover {
    background: rgba(0, 0, 0, 0.13);
}
.footerList{
    display: block;
    width: 50%
}
.footerList li{
    color: white;
    opacity: 1;
    text-transform: uppercase;
    font-size: 16px;
    text-align: left;
    display: block;
    transition: .3s all ease-in-out;
    white-space: nowrap;
}
.footerList li:hover{
    margin-left: 1rem;
}
.col1{
    border-top: 1px solid #3a3a3a;
    border-right: 1px solid #3a3a3a;
    padding-left:  var(--leftRightPadding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.col1 h2 {
    font-size: 48px;
    font-weight: 300;
}
.col1 p {
    /*text-align: justify;*/
    color: rgba(255,255,255,.5);
    max-width: 255px
}
.col2{
    border-top: 1px solid #3a3a3a;
}
.col3{
    border-top: 1px solid #3a3a3a;
}

.col4{
    border-top: 1px solid #3a3a3a;
}

@media screen and (max-width: 800px){
    footer{
        display: flex;
        flex-direction: column-reverse;
        font-size: 14px;
    }
    .col1{
        flex-direction: row;
    }
    .col1 h2{
        font-size: 36px;
    }
    footer > div{
        padding-left:  var(--leftRightPadding);
    }
    .col3 {
        border-top: none
    }
    .col2 {
        border-top: none
    }
    .col{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .addOn{
        display: none;
    }
}