.headingBanner {
    width: 60vw;
    font-size: 1.5rem;
    font-size: clamp(1.5rem, 1.1330275229357798rem + 1.834862385321101vw, 4rem);
    color: white;
    font-weight: 300;
    max-width: calc(100% - 2 * var(--leftRightPadding));
}
.copyright{
    position: absolute;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    color: whitesmoke;
}
.copyright div{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.copyright div a{
    text-decoration: none;
    color: white;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    opacity: 0;
}
.copyright span {
    height: 1px;
    width: 50%;
    background: whitesmoke;
}

@media screen and (max-width: 900px){
    .headingBanner{
        width: 90vw
    }
    .copyright div{
        max-width: calc(100% - 2 * var(--leftRightPadding));
        width: calc(90% - var(--leftRightPadding));
    }
    .copyright span {
        max-width: calc(100% - 2 * var(--leftRightPadding));
        width: calc(90% - var(--leftRightPadding));

    }
}