.secondSectionWrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}
.secondSectionImage{
    display: flex;
    min-height: 100%;
    background-image: url("../assets/wavy.avif");

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.blurBox{
    position: absolute;
    width: calc(100% + 1px);
    padding: 10rem var(--leftRightPadding) 0 var(--leftRightPadding);
    height: calc(100% + 1px);
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(0deg, rgba(26,25,29,1) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(26,25,29,1) 100%);
    backdrop-filter: brightness(.3);
}

ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
}
ul > li{
    width: 100%;
    margin: .5rem;
    font-size: 38px;
    opacity: .5;
    cursor: pointer;
    font-weight: 300;
}
.getToKnowUs{
    color: white;
    font-weight: 300;
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.aboutUsParagraphs{
    margin-top: 32px;
    font-size: 1.125rem;
    font-size: clamp(1.125rem, 1.0263157894736843rem + 0.39473684210526316vw, 1.5rem);
    font-weight: 400;
}
.hashtags{
    margin-top: 7.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
    row-gap: 2.5em;
}
.hashtags span {
    width: max(33%, 300px) !important;
    text-align: center;
    font-size: 36px;
    opacity: .5;
}

@media screen and (max-height: 900px), (max-width: 1100px) {
    .hashtags {
        margin-top: 4.5rem;
    }
}

@media screen and (max-height: 750px), (max-width: 900px) {
    .hashtags {
        justify-content: unset;
        gap: 40px;
        height: unset;
    }
    .hashtags span{
        width: unset !important;
        font-size: 20px;
    }
}

@media screen and (max-height: 600px), (max-width: 750px) {
    .blurBox {
        padding-top: 6rem;
    }
    .getToKnowUs {
        display: unset;
    }
    .hashtags {
        /*display: none;*/
        justify-content: space-evenly

    }
}