.inquiriesContainer {
    /*height: 100vh;*/
    padding: var(--topBottomPadding) var(--leftRightPadding);
    display: flex;
    flex-direction: column;
    color: white;
    /*overflow: hidden;*/
}

.inquiryHeading {
    position: relative;
}

.inquiriesContainer > .inquiryWrapper:nth-child(2) {
    margin-top: 10rem
}

.inquiryWrapper {
    max-width: 100%;
    display: flex;
    padding-bottom: 3vh;
    cursor: pointer;
    transition: opacity .55s ease-in-out;
}
.inquiryWrapper:hover{
    opacity: 1 !important;
}



.inquiryWrapper span {
    margin-top: 1rem;
}
/*.inquiryWrapper:hover ~ .inquiryWrapper:not(:hover){*/
/*    color: blue*/
/*}*/


.inquiryContentWrapper {
    width: 100%;
    margin: 0 2vh;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    min-height: 114px;
}

.inquiryContentWrapper h3 {
    white-space: nowrap;
    font-size: 1.5rem;
    font-size: clamp(1.4rem, 1.125rem + 1.875vw, 3.375rem);
    font-weight: 300;
}

.inquiryContentWrapper code {
    margin-left: 6rem;
    font-size: 5vw;
    font-weight: 100;
    line-height: 1;
}
.buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .inquiriesContainer > .inquiryWrapper:nth-child(2) {
        margin-top: 10rem
    }
   .inquiryContentWrapper{
        min-height: 96px;
    }
}

@media screen and (max-width: 700px) {
    .inquiriesContainer > .inquiryWrapper:nth-child(2) {
        margin-top: 5rem
    }
    .inquiryContentWrapper{
        min-height: 64px;
    }
    .inquiryContentWrapper code{
        margin-left: 1rem;
    }
}
@media screen and (max-width: 550px) {

    .inquiryContentWrapper {
        margin: 0 1vh;
        min-height: 45px;
    }
    .inquiryContentWrapper h3 {
        font-size: 1.2rem;
    }
    .inquiryIndex {
        margin-top: 3px !important;
    }
}
