.menu {
    z-index: 100;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;
    right: -100%;
    top: 0;
    position: fixed;
    align-items: center;
    background-color: rgba(39, 39, 39, 0.92);
    backdrop-filter: blur(10px);
}

.nav {
    opacity: 0;
    margin-bottom: -20px;
}

.nav-link {
    color: white;
    text-decoration: none;
    font-size: 3rem;
    transition: all 0.2s ease-in-out;
    cursor: inherit;
}

.nav-link:hover {
    color: gray;
    transition: all 0.2s ease-in-out;
}

.background {
    position: absolute;
    font-size: 5rem;
    font-size: clamp(5rem, 3rem + 10vw, 15rem);
    user-select: none;
    z-index: 1;
    font-family: cassannet, serif;
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgba(235, 235, 235, 0.04);
    color: transparent;
}

.exit {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 40px;
    opacity: 0;
}

.title {
    font-size: 2rem;
    color: #F7996E
}

.right {
    padding-right: 100px;
}

.information {

    margin-bottom: 24px;
    opacity: 0;
}

.menu-container {
    z-index: 3;
}

.social-medias > a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1px;
    padding: 6px 6px 6px 0
}

.description {
    color: white
}

.options {
    flex-direction: column;
    text-align: center;
}

/* Media Query */
@media screen and (max-width: 660px) {
    .menu {
        flex-direction: column;
        row-gap: 6em;
        justify-content: space-around;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .information {
        text-align: center;
    }
    .exit{
        right: 50% !important;
        transform: translateX(50%);
        top: 10px
    }
    .options{
        margin: 0
    }
    .background{
        margin-top: -1em;
    }
}