/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 3, 2021 */
@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-black-webfont.eot');
    src: url('fonts/strawford-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-black-webfont.woff2') format('woff2'),
    url('fonts/strawford-black-webfont.woff') format('woff'),
    url('fonts/strawford-black-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-bold-webfont.eot');
    src: url('fonts/strawford-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-bold-webfont.woff2') format('woff2'),
    url('fonts/strawford-bold-webfont.woff') format('woff'),
    url('fonts/strawford-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-extralight-webfont.eot');
    src: url('fonts/strawford-extralight-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-extralight-webfont.woff2') format('woff2'),
    url('fonts/strawford-extralight-webfont.woff') format('woff'),
    url('fonts/strawford-extralight-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-light-webfont.eot');
    src: url('fonts/strawford-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-light-webfont.woff2') format('woff2'),
    url('fonts/strawford-light-webfont.woff') format('woff'),
    url('fonts/strawford-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-medium-webfont.eot');
    src: url('fonts/strawford-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-medium-webfont.woff2') format('woff2'),
    url('fonts/strawford-medium-webfont.woff') format('woff'),
    url('fonts/strawford-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-regular-webfont.eot');
    src: url('fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-regular-webfont.woff2') format('woff2'),
    url('fonts/strawford-regular-webfont.woff') format('woff'),
    url('fonts/strawford-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'strawford';
    src: url('fonts/strawford-thin-webfont.eot');
    src: url('fonts/strawford-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/strawford-thin-webfont.woff2') format('woff2'),
    url('fonts/strawford-thin-webfont.woff') format('woff'),
    url('fonts/strawford-thin-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'cassannet';
    src: url('fonts/cassannet_plus_bold-webfont.eot');
    src: url('fonts/cassannet_plus_bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/cassannet_plus_bold-webfont.woff2') format('woff2'),
    url('fonts/cassannet_plus_bold-webfont.woff') format('woff'),
    url('fonts/cassannet_plus_bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "strawford", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html {
    height: 100%
}

#root {
    height: 100%
}

body {
    margin: 0;
    font-family: "strawford", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow-x: hidden;
    background: #1a191d;
}
::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background: white;
}

:root {
    --leftRightPadding: 96px;
    --topBottomPadding: 96px;
}

h3 {
    font-size: 3.375rem;
    font-weight: 300;
}

h2 {
    font-size: 1.5rem;
    font-weight: 300;
}

@media screen and (max-width: 1000px) {
    :root {
        --leftRightPadding: 64px;
        --topBottomPadding: 64px;
    }
    h3 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 800px) {
    h3 {
        font-size: 2.6rem;
    }
}

@media screen and (max-width: 600px) {
    :root {
        --leftRightPadding: 32px;
        --topBottomPadding: 32px;
    }

    h3 {
        font-size: 2.3rem;
    }
    h2 {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 450px) {
    :root {
        --leftRightPadding: 16px;
        --topBottomPadding: 16px;
    }
}