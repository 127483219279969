.introWrapper{
    background: white;
    height: 100%;
    cursor: pointer
}
.introWrapper p {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 100;
    font-size: 1rem;
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);

    max-width: 90vw;
    text-align: center;
    pointer-events: none;
}
.introWrapper div {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column ;
    align-items: center;
}
.tapToContinue{
    font-size: 16px;
}
.accentLine{
    height: 60px;
    width: 1px;
    background: black;
    flex-shrink: 0;
    margin-top: 20px;
    opacity: .5;
}
