.heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.title {
    margin-top: 3rem;
}

.text {
    font-size: 1.6rem;
    margin: 1.25rem 0;
}

.grid {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 3rem;
}

@media screen and (max-width: 1100px){
    .grid {
        grid-template-columns: 1fr;
        gap: 0rem;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

.wrapper {
    /* max-width: 1600px; */
    /* margin: 0 auto; */
    padding: var(--topBottomPadding) var(--leftRightPadding);
    color: white;
    overflow: hidden;
}


.group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.group span {
    display: flex;
    gap: 10px;
    font-size: 1rem;
}

.group span::after {
    content: "/";
}

.group span:last-child::after {
    content: "";
}

.borderTop {
    position: relative;
    width: 100%;
    padding: 2.5rem 0;
}
.divider{
    display: block;
    height: 2px;
    background: white;
    width: 100%
}