a{
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: uppercase;
    color: blue;
    letter-spacing: 1px;
    font-size: 18px;
    transition: 0.5s;
    font-weight: 500 ;
}
a:hover{
    cursor: pointer ;
}

a span {
    display: block;
    position: absolute;
    background: black
}
a span:nth-child(1) {
    left: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;

}
a:hover span:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.5s
}

a span:nth-child(2) {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s
}
a:hover span:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s
}


a span:nth-child(3) {
    right: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;
    transition-delay: 0.5s;
}
a:hover span:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.5s;
    transition-delay: 0.5s;
}


a span:nth-child(4) {
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
    transition-delay: 0.5s;
}
a:hover span:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s;
    transition-delay: 0.5s;
}