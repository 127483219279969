.heading {
    color: #F7996E;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

/*.button {*/
/*    padding: 0.5rem 1rem;*/
/*    font-size: 17px;*/
/*    border-radius: 10px;*/
/*    background: none;*/
/*    border: 1px solid white;*/
/*    margin-top: 2rem;*/
/*    color: white;*/
/*}*/
.form{
    margin: 2rem 0 ;
}


.textarea, .input {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    background: rgba(255,255,255,0.1);
    width: 100%;
    font-size: 1.5rem;
    padding: 10px;
    margin: 1rem 0;
}

.textarea:focus-visible, .input {
    outline: none;
}

.label {
    color: white;
}

.error {
    color: red;
    font-size: 20px;
    margin-top: 10px;
}

.wrapper {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    justify-content: center;
    margin: 2rem 0;
    text-align: center
}
.wrapper button {
    border: none
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 30vw;
}

.flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 40vw;
}

.hidden {
    display: none;
}

.selectButton {
    width: 100%;
    /* height: 200px; */
    aspect-ratio: 1/1;
    padding: 20px;
    font-size: 30px;
    background: none;
    /*border: 2px solid white;*/
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.selectButton:hover {
    background: rgba(0, 0, 0, 0.1);
    border-color: white;
    cursor: pointer;
}


.size {
    background: none;
    width: 100px;
    border: none !important;
    font-size: 1.5rem;
    cursor: pointer;
    filter: invert(.6) ;
}

.size:nth-child(1) {
    width: 100px;
}

.size:nth-child(2) {
    width: 120px;
}

.size:nth-child(3) {
    width: 140px;
}
.size > span{
    color: black
}
.finalSubmitButton{
    margin-top: 1.5rem ;
    margin-left: 1rem;
}