header{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    height: 128px;
    width: 100vw;
    color: white;
    padding: 0 var(--leftRightPadding);
    transition: 1s all ease-in-out;
    font-size: 48px;
    border-bottom: 1px solid rgba(66, 66, 66, 0);
    font-weight: 200;

}
.inActiveHeader{
    height: 84px;
    font-size: 32px;
    font-weight: 400 ;
    background: rgba(66, 66, 66, 0.13);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-bottom: 1px solid rgba(66, 66, 66, 0.33);
    background: rgba(66, 66, 66, 0.13);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-bottom: 1px solid rgba(66, 66, 66, 0.33);
}
/*Arrow*/


div.arrow {
    transition: .7s all ease-in-out;
    opacity: 0;
    cursor: pointer;
    width: 6vmin;
    height: 6vmin;
    margin-top: .5rem;
    transform: rotate(-45deg) scale(.5);
}
div.arrow::before {
    content: '';
    width: 100%;
    height: 100%;
    border-width: 0.5vmin 0.5vmin 0 0;
    border-style: solid;
    border-color: white;
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
}
div.arrow:after {
    content: '';
    float: left;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    border-width: 0 0.5vmin 0 0;
    border-style: solid;
    border-color: white;
    transform-origin: 100% 0;
    transition: 0.2s ease;
}
div.arrow:hover::after {
    transform: rotate(45deg);
    border-color: white;
    height: 120%;
}
div.arrow:hover::before {
    border-color: white;
    transform: scale(0.8);
}
@media screen and (max-width: 769px){
    header{
        flex-direction: column-reverse;
    }
}