main{
    background-color: #1a191d;
    height: 100%;
}
.headingSectionWrapper{
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0 var(--leftRightPadding);
}